<template>
  <span>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <check-box-select
      :choices="schoolYears"
      :initial-value="initSchoolYears"
      :inline="inline"
      @changed="onSchoolYearsChanged($event)"
    >
    </check-box-select>
  </span>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect.vue'
import LoadingGif from '@/components/Controls/LoadingGif'
import { makeSchoolYear } from '@/types/schools'
import { existsIn } from '@/utils/arrays'

export default {
  name: 'active-school-years-multi-select',
  mixins: [BackendMixin],
  components: {
    CheckBoxSelect,
    LoadingGif,
  },
  prop: ['value'],
  props: {
    value: Array,
    inline: {
      type: Boolean,
      default: false,
    },
    loadedSchoolYears: {
      type: Array,
      default: () => { return [] },
    },
  },
  data() {
    return {
      loadingName: 'active-school-years-multi-select',
      selectedSchoolYears: [],
      initSchoolYears: [],
      schoolYears: [],
      activeSchoolYearIds: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async init() {
      if (this.loadedSchoolYears.length) {
        this.schoolYears = this.loadedSchoolYears
      } else {
        await this.loadActiveSchoolYears()
        await this.loadSchoolYears()
      }
    },
    onChange() {
      this.$emit('input', this.selectedSchoolYears)
    },
    onSchoolYearsChanged(event) {
      this.selectedSchoolYears = event.choices
      this.onChange()
    },
    async loadActiveSchoolYears() {
      const backendApi = new BackendApi('get', '/api/active-school-years/')
      try {
        let resp = await backendApi.callApi()
        this.activeSchoolYearIds = resp.data.map(elt => makeSchoolYear(elt)).map(elt => elt.id)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
        if (this.schoolYears.length > 0) {
          this.initSchoolYears = this.schoolYears.filter(elt => existsIn([elt.id], this.activeSchoolYearIds))
          this.selectedSchoolYears = this.initSchoolYears
          this.onChange()
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style lang="less">
</style>
